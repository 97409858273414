var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('heade'),_c('div',{staticClass:"com",style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"com_box"},[_c('div',[_c('div',{staticClass:"com-left"},[_c('div',{staticClass:"com-left-top"},[_vm._v(" "+_vm._s(_vm.practicesDivision.name)+" ")]),_c('div',{staticClass:"com-left-foot nice-scroll"},_vm._l((_vm.practicesDivision.division_category),function(items,indexs){return _c('div',{key:indexs},[(_vm.practicesDivision.division_category.length > 1)?_c('div',{staticClass:"com-left-com-item"},[_vm._v(" "+_vm._s(items.name)+" ")]):_vm._e(),_vm._l((items.division),function(itemst,indexst){return _c('div',{directives:[{name:"trigger",rawName:"v-trigger"}],key:indexst,class:_vm.queryWhere.id == itemst.division_content_id
                    ? 'com-left-foot-but active'
                    : 'com-left-foot-but',attrs:{"id":'trigger' + indexst},on:{"click":function($event){return _vm.getDivisionContentByContentId(itemst.division_content_id)}}},[_vm._v(" "+_vm._s(itemst.name)+" ")])})],2)}),0)]),_c('div',{staticClass:"com-right"},[_c('div',{staticClass:"main_content",domProps:{"innerHTML":_vm._s(_vm.contentDetail.main_content)}})])]),_c('div',{staticClass:"com-btt"},[_c('div',[_c('div',_vm._l((_vm.medicines.practice_medicines),function(item,index){return _c('a',{key:index,class:item.id == _vm.queryWhere.previous
                  ? 'com-bbt-item active'
                  : 'com-bbt-item'},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }